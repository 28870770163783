.newsScreen {
  overflow-x: hidden;
  .MuiContainer-root {
    padding: 0px !important;
  }
  .tagBox {
    background-color: $colorNeutralGrey03;
    border: 1px solid $cardTagBorderColor;
    padding: 6px 20px;
    color: $primaryColorPurple;
    text-transform: uppercase;
    @include border-radius(8px);
  }
  .newsScrollWrapper {
    .verticalCard {
      .MuiCardMedia-img {
        @include border-radius(8px);
      }
      .tags {
        width: 100%;
        bottom: 0px;
        background: linear-gradient(
          180deg,
          rgba(34, 34, 34, 0) 9.59%,
          #222222 94.62%
        );
        .vcardTag {
          @extend .tagBox;
        }
      }
    }
  }

  .bookMarkNews {
    &.lastRead {
      width: 32px;
      min-width: 32px;
      height: 32px;
      span {
        font-size: 20px;
      }
    }
    width: 40px;
    min-width: 40px;
    height: 40px;
    @include border-radius(50%);
    span {
      font-size: 26px;
      color: $white;
    }
  }
  .bookmarked {
    background-color: $primaryColorPurple;
  }
  .unBookmarked {
    background-color: $defaultBookmarkbg;
  }
  .dateColor {
    color: $password-info-text;
  }

  .newsTextTruncate {
    white-space: initial !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .lastReadBookmark {
    .horizontalCard {
      .hcardTag {
        @extend .tagBox;
        @extend .text-ellipsis;
        width: 9.375em;
        margin-right: 2px;
      }
      .hcardBookmark {
        bottom: 0px;
        background: linear-gradient(
          180deg,
          rgba(34, 34, 34, 0) 9.59%,
          #222222 94.62%
        );
      }
    }
  }

  //imgBackSection
  .backImageCenter {
    background-color: rgba(69, 131, 181, 0.1);
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .imgBackSection {
    width: 100%;
    height: 320px;
    @extend .backImageCenter;
  }
  .newsSmallBackImg {
    width: 148px;
    height: 108px;
    @extend .backImageCenter;
  }
}

.overFlow-x-scroll {
  overflow-x: scroll;
  @extend .taskScroll;
  cursor: pointer;
}
